<template>
  <div class="rules-dialog_wrapper">
    <el-dialog
      :visible.sync="dialogVisible"
      width="552"
      class="rules-dialog"
      center
      :show-close="false"
    >
      <div slot="title" class="header">Reglas de integración</div>
      <div slot="default" class="container">
        <p>
          1. Una sola orden completa de 1.000.000, envía 1000 puntos de crédito;
          Completo 5.000.000, envía 6500 créditos; Completo 10.000.000 envía
          15,000 créditos
        </p>
        <p>
          2. Los puntos pueden ser para el oro, 1 crédito para 1 moneda de soso
        </p>
      </div>
      <div slot="footer" class="footer center">
        <div class="close-btn center" @click="close">Lo sé.</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false
    }
  },

  methods: {
    open() {
      this.dialogVisible = true
    },

    close(){
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss">
.rules-dialog_wrapper {
  .rules-dialog {
    .el-dialog__body {
      padding: 30px 0;
    }
    .el-dialog__header {
      padding: 30px 0 10px 0;
    }
    .el-dialog__footer {
      padding: 20px;
    }
    .header {
      color: black;
      font-family: var(--font-family);
      font-size: var(--fs-16);
      font-weight: normal;
      line-height: 21px;
    }

    .container {
      padding: 0 61px;
      p {
        display: flex;
        flex-wrap: wrap;
        color: #606060;
        font-family: var(--font-family);
        font-size: var(--fs-16);
        font-weight: normal;
        line-height: 1;
        text-align: left;

        &:first-child {
          margin-bottom: 30px;
        }
      }
    }

    .footer {
      .close-btn {
        width: 158px;
        height: 48px;
        color: #fff;
        font-family: PingFangSC-Regular-, PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        line-height: 16px;
        background: var(--color-primary);
        border-radius: 28px ;
        cursor: pointer;
      }
    }
  }
}
</style>

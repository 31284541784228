<template>
  <div>
    <nav-bar>
      <div class="edition-center">
        <div class="address-container">
          <div class="flex flex-between">
            <div class="title-bar">Selección del modo de distribución</div>
            <div>
              {{
                distributionType == 'EXPRESS'
                  ? 'Distribución logística'
                  : distributionType == 'SELF'
                  ? 'Puerta a puerta'
                  : 'Entrega a domicilio'
              }}
            </div>
          </div>
          <div
            class="address-content"
            @click="dialogVisible = true"
            v-if="distributionType != 'SELF'"
          >
            <img src="@/assets/order/line.png" alt="" />
            <div v-if="!orderAddress">
              + Por favor, introduzca la dirección de entrega
            </div>
            <div v-else class="address-bar">
              <img
                style="width: 28px; height: 35px; margin-right: 20px"
                src="@/assets/public/dw.png"
                alt=""
              />
              <div class="address-text">
                <p class="address-link">
                  <span>{{ orderAddress.username }}</span>
                  <span class="leftSpace">{{ orderAddress.mobile }}</span>
                </p>
                <div class="address-detail">
                  <span>{{ orderAddress.address }}</span>
                </div>
              </div>
            </div>
            <img src="@/assets/order/line.png" alt="" />
          </div>
        </div>

        <div class="order-container">
          <div class="container-title">Confirmar la información del pedido</div>
          <div class="title-bar">
            <div class="goods">Productos Básicos</div>
            <div>Precio unitario</div>
            <div>Cantidad</div>
            <div>Subtotal</div>
          </div>
          <div class="cartList">
            <div v-for="(item, index) in skus" :key="index">
              <div
                style="color: #d43a57; padding: 10px 15px 0; display: block"
                v-if="item.purchaseLimit"
              >
                <span style="font-weight: bold">Consejos cálidos:</span>
                Todas las especificaciones de este producto están limitadas a
                {{ item.purchaseLimit }} unidades
              </div>
              <div class="cartItem">
                <div class="goods">
                  <card-horizontal :goodsInfo="item"></card-horizontal>
                </div>
                <div class="goods-price">
                  COP {{ item.unitPrice | filterPrice }}
                </div>
                <div>
                  <el-input-number
                    v-model="item.addCount"
                    @change="onAddCountChange($event, index)"
                    :min="0"
                    :max="item.currentAvailableBuyNum || item.storeCount"
                    size="small"
                  ></el-input-number>
                  <div v-if="item.storeCount <= 0" class="storeCountTips">
                    Existencias insuficientes
                  </div>
                </div>
                <div class="goods-price">
                  COP {{ item.totalPrice | filterPrice }}
                </div>
              </div>
            </div>
          </div>

          <div class="operation-container">
            <div class="container-top">
              <div>
                <div>Servicio al cliente</div>
                <div class="voucher-container">
                  <div v-if="serviceName && serviceName" class="flex">
                    <div>
                      <span>{{ serviceName }}</span>
                    </div>
                    <div class="ml15">
                      <span>{{ servicePhone }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>consultor de ventas</div>
                <div class="voucher-container">
                  <div v-if="salesName && salesPhone" class="flex">
                    <div>
                      <span>{{ salesName }}</span>
                    </div>
                    <div class="ml15">
                      <span>{{ salesPhone }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>Método de pago</div>
                <div class="voucher-container">
                  {{ settlementType[typeIndex - 1] }}
                </div>
              </div>
              <div>
                <div>Generar un comprobante</div>
                <div class="voucher-container">
                  {{ billingCertificateType }}
                </div>
              </div>
            </div>
            <div class="container-bottom">
              <div>Observaciones del pedido</div>
              <div style="margin: 10px 0">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="Por favor ingrese observaciones del pedido"
                  v-model="orderMark"
                  resize="none"
                ></el-input>
              </div>
            </div>
          </div>

          <div class="integral_wrapper">
            <h6>completo：</h6>
            <div class="flex items-center">
              <div
                class="no-use_wrapper flex items-center"
                @click="clickIntegralIcon"
              >
                <div class="icon_wrapper mr-10px">
                  <img
                    v-if="!isUsePoints"
                    src="@/assets/carts/checkActive.png"
                    alt=""
                  />
                  <img v-else src="@/assets/carts/check.png" alt="" />
                </div>
                <span>No usar puntos</span>
              </div>

              <div class="use_wrapper flex items-center">
                <div class="icon_wrapper mr-10px" @click="clickIntegralIcon">
                  <img
                    v-if="isUsePoints"
                    src="@/assets/carts/checkActive.png"
                    alt=""
                  />
                  <img v-else src="@/assets/carts/check.png" alt="" />
                </div>
                <div class="span flex items-center">
                  El uso de
                  <input
                    type="number"
                    min="1"
                    v-model="useIntegral"
                    :disabled="!isUsePoints"
                    @blur="changeIntegral"
                  />
                  completo
                </div>
              </div>
              <div class="integral">
                Quedan {{ userInfo.point || 0 }} créditos
              </div>
            </div>
            <div
              class="integral-rules_wrapper flex items-center"
              @click="clickIntegralRules"
            >
              <h6>Reglas de integración</h6>
              <img src="@/assets/order/icon-query.png" alt="" class="ml-10px" />
            </div>
          </div>

          <div class="price-container">
            <p>
              <span class="label">Subtotal: COP</span>
              <span class="value" style="font-size: 20px">
                {{ priceOriginal | filterPrice }}
              </span>
            </p>
            <p>
              <span class="label">Descuento: COP</span>
              <span class="value">{{ this.discountPrice | filterPrice }}</span>
            </p>
            <p>
              <span class="label">IVA: COP</span>
              <span class="value">{{ this.taxPoint | filterPrice }}</span>
            </p>
            <p>
              <span class="label">Flete: COP</span>
              <span class="value">{{ shipPrice | filterPrice }}</span>
            </p>
            <p>
              <!-- 积分 -->
              <span class="label">completo: -COP</span>
              <span class="value">{{ useIntegral }}</span>
            </p>
            <p style="font-weight: bold">
              <span class="label">Total:</span>
              <span class="value" style="color: #d43a57">
                COP {{ this.totalPrice | filterPrice }}
              </span>
            </p>
          </div>

          <div class="tips">
            En los precios no esta incluido el IVA. Cuando se emitan las facturas se agregará el 19% de IVA para los accesorios y arena de gato y 5% de IVA para alimentos.
          </div>

          <div class="create-btn">
            <el-button
              type="success"
              round
              :loading="createLoading"
              :style="{ 'font-size': '18px' }"
              @click="orderConfirm"
              :disabled="submitDisabled"
            >
              Enviar pedido
            </el-button>
          </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="1200px">
          <address-index
            :selectIf="true"
            @selectId="resetAddress"
          ></address-index>
        </el-dialog>

        <rulesDialogVue ref="rulesDialogRef"></rulesDialogVue>
      </div>
    </nav-bar>
  </div>
</template>

<script>
// @ts-nocheck
import navBar from '@/components/navBar/index.vue'
import cardHorizontal from '@/components/product/card-horizontal.vue'

import addressIndex from '@/views/mine/address'
import rulesDialogVue from './components/rules-dialog.vue'
import utils from 'my-tools-library'

export default {
  components: {
    navBar,
    cardHorizontal,
    addressIndex,
    rulesDialogVue
  },

  data() {
    return {
      num: 1,
      value: '',
      textarea: '',

      submitSkuInfo: '',
      orderAddressId: '',
      orderAddress: '',
      skus: [],
      salesmanList: [],
      salesmanId: '',
      settlementType: [
        'Pago inmediato',
        'Pago contra entrega',
        'Pago con crédito'
      ],
      typeIndex: '',
      billingCertificateType: '',
      priceOriginal: '',
      discountPrice: '',
      taxPoint: '',
      shipPrice: '',
      totalPrice: '',
      dialogVisible: false,
      orderMark: '',
      createLoading: false,
      timerList: [],
      distributionType: '',
      salesName: '',
      salesPhone: '',
      serviceName: '',
      servicePhone: '',
      isUsePoints: false,
      useIntegral: 0,
      userInfo: {},
      submitDisabled: true
    }
  },

  created() {
    if (this.$route.params.skus) {
      // 规格参数
      this.submitSkuInfo = this.$route.params.skus
    } else {
      // 当前页面刷新则返回商品详情页
      this.$router.back(-1)
      return
    }

    this.getUserIntegral()

    this.$axios.post('member/info', {}).then((res) => {
      this.$store.commit('SET_INFO', res.data.result)
      this.info = res.data.result
      console.log('用户信息', this.info)
      this.distributionType = this.info.distributionType
      this.salesName = this.info.salespersonDetail.employeeName
      this.salesPhone = this.info.salespersonDetail.employeePhone

      this.serviceName = this.info.customerServiceDetail.employeeName
      this.servicePhone = this.info.customerServiceDetail.employeePhone

      this.billingCertificateType =
        this.info.billingCertificateType === 'RECEIPT'
          ? 'Recibos'
          : 'Facturas (19% IVA)'
      this.typeIndex = this.info.settlementType
    })

    this.getDefaultAddress()

    this.getEmployeeList()
  },

  methods: {
    // 获取用户积分
    getUserIntegral() {
      this.$axios.post('/wallet/myWallet', {}).then((res) => {
        const { result } = res.data
        this.userInfo.point = result.point
      })
    },

    onAddCountChange($event, skuIndex) {
      this.submitSkuInfo[skuIndex].num = $event
      this.getOrderPrice()
    },

    // 获取默认地址
    getDefaultAddress() {
      this.$axios.post('/memberShip/detail/default', {}).then((res) => {
        this.orderAddress = res.data.result
        // 得到默认地址后请求此接口
        this.getOrderPrice()
      })
    },

    // 获取订单价格

    getOrderPrice() {
      this.submitDisabled = true

      let params = {
        integral: this.isUsePoints ? this.useIntegral : 0,
        shopOptions: [
          {
            shopId: 1,
            buyGoodsSkuses: this.submitSkuInfo,
            memberShipId: this.orderAddress.id || '',
            deliveryType: 'EXPRESS'
          }
        ]
      }
      this.$axios.post('/order/preCreateOrder', params).then((res) => {
        const result = res.data.result.shopInfoDtos[0]
        console.log('订单价格', res.data.result)
        // 积分
        this.useIntegral = res.data.result.integral
        this.originalPrice = result.priceOriginal
        this.shipPrice = result.priceDelivery
        this.couponPrice = result.orderShopCouponPreferentialDto || 0
        this.totalPrice = res.data.result.totalPayPrice
        this.discountPrice = result.pricePreferentialShop
        this.priceOriginal = result.priceOriginal
        this.taxPoint = res.data.result.taxPoint ? res.data.result.taxPoint : 0

        this.skus = []
        this.uinCount = 0
        result['buyGoodsSkuses'].map((item, index) => {
          const sku = item.goodsSkuDetail
          const product = item.goodsDetail
          this.uinCount += item.num

          sku.spec = JSON.parse(sku.spec)

          this.skus.push({
            id: product.id,
            goodsId: product.id,
            sn: sku.sn,
            name: product.name,
            addCount: item.num,
            purchaseLimit: item.goodsDetail.purchaseLimit,
            storeCount: item.goodsSkuDetail.storeCount,
            unitPrice: item.goodsSkuDetail.price,
            specs: sku.spec,
            totalPrice: result.item[index].originalPrice,
            deductionPrice: item.goodsSkuDetail.deductionPrice,
            coverUrl: product.coverUrl,
            starRating: product.starRating,
            participationDiscount: item.goodsDetail.participationDiscount,
            currentAvailableBuyNum: item.goodsDetail.currentAvailableBuyNum
          })
          this.submitDisabled = false

          console.log('sku商品列表', this.skus)
        })
      })
    },

    // 员工列表
    getEmployeeList() {
      this.$axios
        .post('/employee/list', { onJob: 1, position: 'SALES' })
        .then((res) => {
          let employeeList = res.data.result.rows
          console.log('employeeList', employeeList)
          for (let i = 0; i < employeeList.length; i++) {
            this.$set(this.salesmanList, i, {
              label:
                employeeList[i].employeeName +
                ' ' +
                employeeList[i].employeePhone,
              id: employeeList[i].id
            })
          }
        })
    },

    orderConfirm() {
      if (!this.distributionType)
        return this.$message.error(
          'Póngase en contacto con el administrador para agregar un método de recepción'
        )
      if (this.distributionType != 'SELF') {
        if (!this.orderAddress)
          return this.$message.error(
            'La Dirección de recepción no puede estar vacía'
          )
      }
      if (!this.serviceName && this.servicePhone)
        return this.$message.error(
          'Comuníquese con el administrador para agregar servicio al cliente'
        )
      if (!this.salesPhone && this.salesName)
        return this.$message.error(
          'Comuníquese con el administrador para agregar ventas'
        )

      this.createLoading = true

      let params = {
        integral: this.useIntegral,
        childOrderId: this.$store.state.order.childrenId,
        price: this.totalPrice,
        shopOptions: [
          {
            shopId: 1,
            buyGoodsSkuses: this.submitSkuInfo,
            memberShipId: this.orderAddress.id || '',
            deliveryType: 'EXPRESS',
            remark: this.orderMark,
            salesEmployeeId: this.salesmanId
          }
        ]
      }
      this.$axios
        .post('/order/createOrder', params)
        .then((res) => {
          this.$store.dispatch('getUserIntegral')
          this.$router.push('/mine')
        })
        .catch(() => {
          this.createLoading = false
        })
    },

    resetAddress(id) {
      this.orderAddressId = id
      this.dialogVisible = false
      this.getAddresses()
    },

    getAddresses() {
      console.log(1)
      this.$axios.post('/memberShip/list', {}).then((res) => {
        this.addresses = res.data.result.rows

        this.orderAddress = this.addresses.find((item) => {
          return item.id === this.orderAddressId
        })
      })
    },

    clickIntegralIcon() {
      this.isUsePoints = !this.isUsePoints

      if (!this.isUsePoints) {
        this.getOrderPrice()
      }
    },

    changeIntegral() {
      this.getOrderPrice()
    },

    clickIntegralRules() {
      this.$refs.rulesDialogRef.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.edition-center {
  width: 1200px;
  margin: 0 auto;
}

.address-container {
  margin: 40px 0;
  .title-bar {
    font-size: 20px;
    font-weight: bold;
  }
  .address-content {
    margin-top: 20px;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
    }
    > div {
      padding: 40px 0;
      color: #979797;
      font-size: 18px;
    }
    .address-bar {
      display: flex;
      .address-text {
        flex: 1;
        .address-link {
          color: #000;
          .leftSpace {
            margin-left: 15px;
          }
        }
      }
      .address-detail {
        margin-top: 4px;
      }
    }
  }
}

.order-container {
  .container-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  .title-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    color: #979797;
    font-size: 18px;
    text-align: center;
    border-bottom: 1px solid #e7e7e7;
    > div {
      flex: 1;
      &.goods {
        flex: 3;
        text-align: left;
      }
    }
  }

  .cartList {
    .cartItem {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      font-size: 18px;
      text-align: center;
      border-bottom: 1px solid #e7e7e7;
      > div {
        flex: 1;
        &.goods {
          flex: 3;
        }
        &.goods-price {
          color: #d43a57;
          font-size: 20px;
        }
      }

      .select-icon {
        flex: none;
        width: 18px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
    }
  }

  .operation-container {
    padding: 40px 0;
    border-bottom: 1px solid #e7e7e7;
    .container-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .voucher-container {
        width: 230px;
        height: 40px;
        padding: 0 20px;
        margin: 10px 0;
        color: #979797;
        font-size: 16px;
        line-height: 40px;
        background: #e7e7e7;
        border-radius: 4px;
      }
    }

    .container-bottom {
      ::v-deep .el-textarea__inner {
        font-size: 16px;
      }
    }
  }

  .price-container {
    padding: 40px 0;
    color: #282828;
    font-size: 20px;
    text-align: right;
    p {
      padding: 5px 0;
    }
    .value {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .tips {
    padding: 0 0 50px 400px;
    font-weight: bold;
  }

  .create-btn {
    text-align: right;
    ::v-deep .el-button.is-round {
      width: 400px;
      height: 56px;
      border-radius: 28px;
    }
  }

  .integral_wrapper {
    padding: 11px 0;
    border-bottom: solid 1px #e7e7e7;
    h6 {
      color: black;
      font-family: var(--font-family);
      font-size: var(--fs-20);
      font-weight: normal;
      line-height: 27px;
    }

    .no-use_wrapper {
      cursor: pointer;
      span {
        color: #000;
        font-family: var(--font-family);
        font-size: var(--fs-20);
        font-weight: normal;
        line-height: 27px;
      }
    }

    .use_wrapper {
      margin-left: 98px;
      font-family: var(--font-family);
      cursor: pointer;
      .span {
        color: #000;
        font-size: var(--fs-20);
        font-weight: normal;
        line-height: 27px;
        white-space: nowrap;
      }
      input {
        width: 87px;
        height: 27px;
        padding: 0 10px;
        margin: 0 10px;
        border: 1px solid #262626;
        border-radius: 22px;
        outline: none;
        outline: 0;
        opacity: 1;
      }
    }

    .integral {
      margin-left: 39px;
      color: #979797;
      font-size: var(--fs-16);
      font-weight: normal;
      line-height: 21px;
    }
    .icon_wrapper {
      width: 21px;
      height: 21px;
      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
      }
    }

    .integral-rules_wrapper {
      margin-top: 11px;
      cursor: pointer;
      h6 {
        color: var(--color-primary);
        font-size: var(--fs-20);
        font-weight: normal;
        line-height: 27px;
      }
      img {
        width: 20px;
        height: 20px;

        object-fit: contain;
      }
    }
  }
}
</style>
<style lang="scss">
.useIntegral-input_wrapper {
  input {
    width: 87px;
    height: 27px;
    border-radius: 22px;
    opacity: 1;
    border: 1px solid #262626;
  }
}
</style>
